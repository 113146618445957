import './content4.scss';
import contentImg3 from '../../../img/page-contant/content4/ct1111.jpg';
import contentImg3mob from '../../../img/page-contant/content4/ct1111-mob.jpg'

const Content4 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'content4');
 
	contentElement.innerHTML = `
	<div class='content4__container'>
	<img class="content4__img-mob" width="432" height="210" src="${contentImg3mob}">
	<img class="content4__img" width="1180" height="310" src="${contentImg3}">
	
	<div class="content4__body">
	<h2 class="content4__title">

	Как работает автоматическая система для заработка в интернете
	</h2>
		<p class="content4__text">
		Основная цель проекта Маска – покорение других планет солнечной системы для переселения части человечества. Илон Маск всегда мечтал сделать космические полеты доступными для людей. На Space X как заработать на фантастических идеях гениального выдумщика можно с помощью уникальной платформы, создающей автоматические финансовые операции. Команда ученых под руководством Илона Маска создала платформу, на которой можно зарабатывать в автоматическом режиме без участия человека. Было создано программное обеспечение с возможностями для Space X заработка.
		
		</p>
	<p class="content4__text">
	Там можно настроить краткосрочные и долгосрочные инвестиции, торговать разными активами. На площадке есть возможность диверсифицировать портфель, чтобы снизить риски потери инвестиций. Платформа обеспечивает постоянный рост доходов, защищает данные пользователей от внешних посягательств. Она торгует в автоматическом режиме, выбирая для этого лучшую стратегию, анализируя все изменения рынка. В результате сотни пользователей оставляют положительные отзывы о платформе Space X. Для того чтобы принять участие в инвестиционной программе, нужно зарегистрироваться на официальном сайте компании.
	
	</p>

	</div>
		

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Content4;