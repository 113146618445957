import './contents3.scss';
import contentImg3 from '../../../img/page-contant-2/content2-3/ct222.jpg';
import contentImg3mob from '../../../img/page-contant-2/content2-3/ct222-mob.jpg'

const Contents3 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contents3');
 
	contentElement.innerHTML = `
	<div class='contents3__container'>
	<img class="contents3__img-mob" width="432" height="210" src="${contentImg3mob}">
	<img class="contents3__img" width="580" height="650" src="${contentImg3}">
	
	<div class="contents3__body">
	<h2 class="contents3__title">

	Почему инвестиции в недвижимость выгодны

	</h2>
		<p class="contents3__text">
		Есть несколько ключевых причин, почему это может быть выгодным. Это динамично развивающийся рынок недвижимости, который характеризуется строительством новых проектов, современных жилых комплексов, бизнес-центров и развлекательных объектов. Дубай один из самых посещаемых городов мира с развивающимся туристическим сектором, благодаря чему постоянно есть высокий спрос на аренду жилья (доходность от сдачи недвижимости в аренду там может достигать 6-10% годовых, что выше, чем в большинстве развитых стран). В ОАЭ отсутствует налог на доходы физических лиц и корпоративный налог на доходы, что делает его привлекательным для инвесторов. Город занимает выгодное географическое положение между Европой, Азией и Африкой, является крупным транспортным и логистическим узлом, что способствует стабильному росту спроса на недвижимость.</p>

	</div>
		

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contents3;