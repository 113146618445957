import './contentt2.scss';
// import contentImg from '../../../img/page-content-3/ct222/ct33.jpg';
// import contentImgMob from '../../../img/page-content-3/ct222/ct33-mob.jpg';

const Contentt2 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentt2');
 
	contentElement.innerHTML = `
	<div class='contentt2__container'>
	
	
	<div class="contentt2__body">
	<h2 class="contentt2__title">

Возможно ли получать профит, заходя на площадку со смартфона?

	</h2>
		<p class="contentt2__text">
			Торговля на площадке доступна не только на ПК. Деньги можно зарабатывать на смартфонах, скачав приложение WTBot. Пользователи через него могут открывать счет, настраивать бот, торговать и контролировать свою учетную запись. Приложение доступно для владельцев устройств, работающих на ОС Android, iOS. Его можно найти в App Store и Google Play. У площадки есть такие преимущества - инвестор может выбирать долгосрочную или краткосрочную торговлю, там есть возможность диверсификации портфеля, что исключает финансовые потери. Программа самостоятельно проводит анализ рынков, мгновенно реагируя на любые изменения, благодаря чему сделки прибыльные.

		</p>
<img class="contentt2__img-mob" width="432" height="210" src="${contentImgMob}">
	
	</div>
		

	<img class="contentt2__img" width="600" height="672" src="${contentImg}">
	</div>
	<div class="contentt2__container">
		<p class="contentt2__text">
			ИИ не подвержен воздействию эмоций, что исключает принятие необоснованных решений. Искусственный интеллект может торговать сразу несколькими парами инструментов, обрабатывая внушительные объемы информации. Боту не нужно отдыхать, и программа может работать в круглосуточном режиме, торгуя на биржах, которые открывают позиции в ночное время. Робот сам составляет или подбирает лучшие стратегии, которые приносят прибыль. На платформе для заработка WTBot пользователям доступен большой выбор торговых инструментов. Инвесторам не нужно торговать в ручном режиме, так как есть риск потери финансов. Они могут использовать бот, который будет приносить пассивный доход, самостоятельно заключая сделки.

		</p>

		<p class="contentt2__text">
			Инвестиции в WTBot доступны практически для всех, кто хочет зарабатывать на вложениях. Площадка для трейдинга использует алгоритм, который исключает ошибки во время торговли и делает процесс более эффективным и прибыльным. Доходность увеличивается и за счет того, что бот торгует не только в дневное, но и в ночное время. Размер профита зависит от того, какая сумма была инвестирована изначально трейдером. Заработок на площадке пассивный, что открывает широкие возможности перед начинающими инвесторами. Им не нужно проходить сложное обучение, годами торговать в убыток на различных площадках ради опыта. Все процессы выполняются в автоматическом режиме.		

		</p>
		<p class="contentt2__text">
				У бота есть ряд неоспоримых плюсов - большой выбор инструментов для торговли, быстрая реакция на изменения рынков. торговля строго по алгоритмам, при которой исключается человеческий эмоциональный фактор, круглосуточная работа в режиме 24/7, самостоятельный выбор стратегии, открытие и закрытие сделок по лучшим ценам.
О том на WTBot как зарабатывать рассказывают в отзывах много пользователей. Они упоминают о простоте процесса и прибыльности такой торговли. 
 <br>Автоматизированный терминал, если прочитать об инвестициях в WTBot отзывы, привлекает внимание многих инвесторов. И это происходит потому, что там действительно можно зарабатывать, причем пассивно. Все действия выполняются ботом. Но насколько деятельность платформы является законной? Площадка работает на законных основаниях, и ей выдана лицензия от финансового регулятора.
		</p>
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contentt2;